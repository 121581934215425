import "./info-box-first-order-credits.scss";
import Modal from "./modal";
import ErrorModal from "./error-modal";
import useIsEligibleForFirstOrderCredits from "../hook/useIsEligibleForFirstOrderCredits";
import {useEffect, useState} from "react";

export default function InfoBoxFirstOrderCredits() {

  const {isEligibleForFirstOrderCredits, errors, setErrors} = useIsEligibleForFirstOrderCredits();
  const [showModal, setShowModal] = useState(isEligibleForFirstOrderCredits);

  useEffect(() => {
    setShowModal(isEligibleForFirstOrderCredits);
  }, [isEligibleForFirstOrderCredits]);

  return (
    <>
      {
        showModal &&
        <div className="InfoBoxFirstOrderCredits">
          <Modal onDismiss={() => setShowModal(false)}>
            <div className="upper">
              <div className="text">
                <h2>
                  Der Landewyck Bonusclub –<br/>Ihre Treue zahlt sich aus!
                </h2>
                <p>
                  Sammeln Sie Bonuspunkte durch Einkäufe in unserem Onlineshop und lösen diese gegen Ihre Wunschprämie
                  ein.
                </p>
              </div>
              <img
                src={process.env.PUBLIC_URL + '/landewyck-bonus-club-ohne-turm.svg'}
                alt="Landewyck Deutschland Bonusclub"
              />
            </div>
            <div className="lower">
              <img
                src={process.env.PUBLIC_URL + '/landewyck-100-jahre-bonuspunkte.svg'}
                alt="100 Jahre Landewyck (1925-2025) - Bonuspunkte für die erste Bestellung"
              />
              <p className="cta">
                Jetzt im Landewyck Online-Shop einkaufen und{' '}
                <strong>100 Bonuspunkte für Ihre erste Bestellung sichern.</strong>
              </p>
              <p className="info">
                Aktionszeitraum <span>01.04. - 30.06.2025</span>
              </p>
            </div>
          </Modal>
        </div>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>
  );

}
