import Section from "../component/section";
import {useContext, useEffect, useState} from "react";
import {ShoppingCartContext} from "../context/shopping-cart-context";
import Overline from "../component/overline";
import useCRUD from "../hook/useCRUD";
import ErrorModal from "../component/error-modal";
import IconAndText from "../component/icon-and-text";
import {Loader} from "../component/icons";
import LineItem from "../component/line-item";
import Widget from "../component/widget";
import Price from "../component/price";
import ButtonLink from "../component/button-link";
import Row from "../component/row";
import EmptyCart from "../component/empty-cart";
import useClaims from "../hook/useClaims";
import useShowCredits from "../hook/useShowCredits";
import useIsEligibleForFirstOrderCredits from "../hook/useIsEligibleForFirstOrderCredits";

export default function Warenkorb() {

  const shoppingCartContext = useContext(ShoppingCartContext);
  const crud = useCRUD();
  const claims = useClaims();
  const showCredits = useShowCredits();
  const [lineItems, setLineItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const {isEligibleForFirstOrderCredits} = useIsEligibleForFirstOrderCredits();

  const setAmount = (lineItem, amount) => {
    if (amount === 0) {
      lineItem.product_id && shoppingCartContext.removeFromCart('product', lineItem.product_id);
      lineItem.promotion_id && shoppingCartContext.removeFromCart('promotion', lineItem.promotion_id);
    } else {
      lineItem.product_id && shoppingCartContext.setAmount('product', lineItem.product_id, amount);
      lineItem.promotion_id && shoppingCartContext.setAmount('promotion', lineItem.promotion_id, amount);
    }
  };

  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);
    crud.expand(shoppingCartContext.shoppingCart.line_items, ['promotion', 'product'])
      .then(async lineItems => {
        const expansions = await crud.expand(
          lineItems.filter(lineItem => lineItem.product || lineItem.promotion).map(lineItem =>
            lineItem.product ? {$entity: 'product', ...lineItem.product} : {$entity: 'promotion', ...lineItem.promotion}
          ),
          ['image:file', 'product_type', 'brand']
        );
        for (const lineItem of lineItems) {
          for (const $entity of ['promotion', 'product']) {
            if (lineItem[$entity]) {
              const expansion = expansions.find(ex => ex.$entity === $entity && ex.id === lineItem[$entity + '_id']);
              delete expansion.$entity;
              lineItem[$entity] = expansion;
            }
          }
        }
        // NR-Produkte nach hinten sortieren
        lineItems.sort((a, b) => {
          // Prüfen, ob a und b die Eigenschaft "product.free_good" haben
          const aFreeGood = a?.product?.free_good === '1';
          const bFreeGood = b?.product?.free_good === '1';
          // Wenn a ein "free_good" ist und b nicht, sortiere b vor a
          if (aFreeGood && !bFreeGood) {
            return 1;
          }
          // Wenn b ein "free_good" ist und a nicht, sortiere a vor b
          if (bFreeGood && !aFreeGood) {
            return -1;
          }
          // Wenn beide gleich sind, bleibt die Reihenfolge unverändert
          return 0;
        });
        setLineItems(lineItems);
      })
      .catch(errors => setErrors(errors))
      .finally(() => clearTimeout(timeout) || setShowLoading(false) || setLoading(false));
    return () => clearTimeout(timeout);
  }, [crud, shoppingCartContext]);

  return (
    <>
      <Section background>
        <Overline>Warenkorb</Overline>
        <h1 className="narrow">Fast geschafft</h1>
      </Section>
      {loading && showLoading && <Section background><IconAndText icon={<Loader/>} text="Laden…"/></Section>}
      {
        !loading && !lineItems.length &&
        <Section background noPadding><EmptyCart/></Section>
      }
      {lineItems.length > 0 && (
        <Section background>
          <div className="LineItem description">
            <div className="image"/>
            <div className="text">
              <div className="title">Produkt</div>
              <div className="unit-price">Preis</div>
              <div className="total-price">Gesamtsumme</div>
              <div className="AmountSelection">Anzahl</div>
            </div>
            <div className="action"/>
          </div>
          {lineItems.filter(lineItem => lineItem.promotion).map((lineItem, index) => (
            <LineItem key={index} lineItem={lineItem} setAmount={setAmount}/>
          ))}
          {lineItems.filter(lineItem => lineItem.product).map((lineItem, index) => (
            <LineItem key={index} lineItem={lineItem} setAmount={setAmount}/>
          ))}
          {
            showCredits &&
            <Widget className="color-secondary text-right bt">
              {!isEligibleForFirstOrderCredits && 'Für diesen Einkauf erhalten Sie '}
              {isEligibleForFirstOrderCredits && 'Für Ihren ersten Einkauf erhalten Sie '}
              {
                shoppingCartContext.shoppingCart.credits === 0 &&
                ' keine '
              }
              {
                shoppingCartContext.shoppingCart.credits > 0 &&
                <span className="highlight-text">{shoppingCartContext.shoppingCart.credits}</span>
              }
              {' Punkte.'}
            </Widget>
          }
          <Row columns={{default: 1, md: 2}} justify="end">
            <div className="mt-1">
              {
                !claims.sales && !claims.field_sales &&
                <>
                  <p className="mt-1 small">
                    Hinweis: Die tatsächliche Gesamtsumme richtet sich nach Ihren individuellen Konditionen.
                  </p>
                  {
                    showCredits && shoppingCartContext.shoppingCart.credits > 0 &&
                    <p className="small">
                      Die Punkte aus dieser Bestellung werden Ihnen nach 14 Tagen gutgeschrieben.
                    </p>
                  }
                </>
              }
              <Widget>
                <Row gap={.25} columns={{default: 2}} justify="center">
                  <span className="color-secondary">Gesamtsumme</span>
                  <Price
                    amount={
                      lineItems.reduce(
                        (sum, lineItem) =>
                          sum + lineItem.amount * (
                            parseInt(lineItem?.product?.price ?? 0) +
                            parseInt(lineItem?.promotion?.price ?? 0)
                          ),
                        0
                      )
                    }
                    align="right"
                  />
                </Row>
              </Widget>
              <ButtonLink to="/bestellen" text="Weiter zur Bestellung"/>
            </div>
          </Row>
        </Section>
      )}
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>
  );

}
