import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../context/auth-context";
import {PosContext} from "../context/pos-context";
import useBL from "./useBL";

export default function useIsEligibleForFirstOrderCredits() {

  const authContext = useContext(AuthContext);
  const posContext = useContext(PosContext);
  const bl = useBL();

  const [isEligibleForFirstOrderCredits, setIsEligibleForFirstOrderCredits] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (authContext.authToken && posContext.activePos?.id) {
      bl.is_eligible_for_first_order_credits({point_of_sale_id: posContext.activePos.id})
        .then(setIsEligibleForFirstOrderCredits)
        .catch(errors => setErrors(errors));
    }
  }, [authContext.authToken, posContext.activePos?.id, bl]);

  return {isEligibleForFirstOrderCredits, errors, setErrors};

}
